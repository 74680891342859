import React from "react";
import Button from "../../../../Partials/Button";

export default function Upload () {
    return (
        <div className="inline-flex justify-center mt-5 h-min">
            <Button className={'border-0 bg-[#2B4984] text-white w-40 py-1 mx-2'}>
                <span>Learn More</span>
            </Button>
            <Button className={'border-[rgb(43,73,132)] w-40 py-1 mx-2'}>
                <span>Upload</span>
            </Button>
        </div>
    )
}