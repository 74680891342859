import React from "react";
import Customizer from "./pages/Customizer";
import { Provider } from "react-redux";
import { store } from "./store/store";
export default function App() {
  return (
    <Provider store={store}>
      <Customizer/>     
    </Provider> 
  );
}
