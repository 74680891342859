import React from "react";
import { useDispatch } from "react-redux";
import { setColor } from "../../../../../store/slice"; 

function Color() {
  const dispatch = useDispatch(); // Use dispatch to send actions to the store

  const colors = [
    "#56FF94",
    "#7FFF82",
    "#ADE153",
    "#BF572D",
    "#FD2B2B",
    "#B93877",
    "#C797FE",
    "#6793FE",
    "#9421B4",
    "#A74EEC", 
    "#4A3BFE",
    "#2C93FE",
  ];

  const handleColorClick = (color) => {
    dispatch(setColor(color)); // Dispatch the color to the Redux store
  };

  return (
    <div className="m-auto p-2 flex flex-wrap justify-center gap-8 ">
      {colors.map((color, index) => (
        <button key={index} onClick={() => handleColorClick(color)} >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle cx="16" cy="16" r="16" fill={color} />
          </svg>
        </button>
      ))}
    </div>
  );
}

export default Color;
