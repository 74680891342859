import React, {useState} from "react";
import { Decal } from "@react-three/drei";
import { useSelector } from "react-redux";
import * as THREE from "three";

export default function DecalLogo() {
  const selectedLogoTexture = useSelector(
    (state) => state.customizer.selectedLogoTexture
  );
  const formattedLogoTexture = selectedLogoTexture
    ? selectedLogoTexture.replace(/\s+/g, "")
    : null;

  console.log(selectedLogoTexture);

  const textureLoader = new THREE.TextureLoader();
  const Texture = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/${formattedLogoTexture}.png`
  );
  const Normal = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/Normal.png`
  );
  const Roughness = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/Roughness.png`
  );
  const Metalness = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/Metalness.png`
  );
  const Sheen = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/Sheen.png`
  );
  const Displacement = textureLoader.load(
    `/LogoTextures/${formattedLogoTexture}/Displacement.png`
  );

  // Baked material
  // const bakedMaterial = new THREE.MeshBasicMaterial({
  //     map: bakedTexture,
  //     polygonOffsetFactor: -1,
  //     toneMapped: false,

  // })

  const [pos, setPos] = useState([3.8, 0, 1]);
  const [rotation, setRotation] = useState([0, -1.571, 0]);
  const [scale, setScale] = useState([4, 4, 5]);

  return (
    <>
      {selectedLogoTexture ? (
        <Decal position={pos} rotation={rotation} scale={scale}>
          <meshPhysicalMaterial
            map={Texture}
            map-anisotropy={16}
            normalMap={Normal}
            roughnessMap={Roughness}
            metalnessMap={Metalness}
            sheenColorMap={Sheen}
            // displacementMap={Displacement}
            // displacementScale={1}
            normalScale={(1, 1)}
            toneMapped={false}
            transparent
            polygonOffset
            polygonOffsetFactor={-1}
          />
        </Decal>
      ) : null}
    </>
  );
}
