import React from "react";
import TextureSet from "../../../../../assets/textures";

export default function Textures() {
  return (
    <div className="w-max overflow-x-auto h-full py-5" >
      <TextureSet />
    </div>
  );
}
