import React from "react";
import Menu from "../components/Customizer/Menu";
import TopBar from "../components/Customizer/Topbar";
import Scene from "../components/Customizer/Scene";
export default function Customizer() {
  return (
    <div className="font-inter w-screen h-screen">
      <TopBar />
      <Scene />
      <Menu />
    </div>
  );
}
