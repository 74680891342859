import React from "react";
import Upload from "./Upload";
import Textures from "./Textures";

export default function Logo () {
    return(
        <div className="w-full sm:flex gap-5 p-2">
            <Upload/>
            <Textures/>
        </div>
    )
}