import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPart } from "../../../../store/slice";
import { setColor } from "../../../../store/slice";

export default function Selection() {
  const dispatch = useDispatch();
  const selectedPart = useSelector((state) => state.customizer.selectedPart);

  const parts = [
    "Vamp",
    "InSole",
    "OuterSole",
    "Inside",
    "Tongue",
    "Lace",
    "Foxing",
  ];

  // Initialize currentIndex based on the selectedPart or default to the first part
  const [currentIndex, setCurrentIndex] = useState(
    parts.indexOf(selectedPart) !== -1 ? parts.indexOf(selectedPart) : 0
  );

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? parts.length - 1 : prevIndex - 1
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === parts.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePartChange = () => {
    dispatch(setPart(parts[currentIndex]));
    dispatch(setColor(null));
  };

  // Update selected part when currentIndex changes
  useEffect(() => {
    handlePartChange();
  }, [currentIndex, dispatch]);

  return (
    <div className="absolute left-1/2 top-[50%] h-auto transform -translate-x-1/2 -translate-y-1/2">
      <div className="flex items-center gap-16">
        <button
          onClick={handleLeftClick}
          aria-label="Previous Part"
          className="hover:svg-hover"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15.75 19.5L8.25 12L15.75 4.5"
              stroke="#848484"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="flex items-center gap-2">
          <span>{parts[currentIndex]}</span>
          <span className="text-gray-300">
            {currentIndex + 1}/{parts.length}
          </span>
        </div>
        <button onClick={handleRightClick} aria-label="Next Part">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.25 4.5L15.75 12L8.25 19.5"
              stroke="#848484"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
