import React from "react";
import { useDispatch } from "react-redux";
import { setLogoTexture } from "../../store/slice";

import RecycledBambooChip from "./png/RecycledBambooChip.png";
import Plastisol from "./png/Plastisol.png";
import FeltBadge from "./png/FeltBadge.png";
import HeatTransfer from "./png/HeatTransfer.png";
import TPU from "./png/TPU.png";
import MarbleTPU from "./png/MarbleTPU.png";
import RecycledPlasticGrind from "./png/RecycledPlasticGrind.png";
import RecycledCoffeeGrind from "./png/RecycledCoffeeGrind.png";
import SandblastedReflective from "./png/SandblastedReflective.png";
import Reflective from "./png/Reflective.png";
import LaserCutBadge from "./png/LaserCutBadge.png";
import DebossBadge from "./png/DebossBadge.png";
import LeatherBadge from "./png/LeatherBadge.png";
import LeatherBadgeLaserCut from "./png/LeatherBadgeLaserCut.png";
import VisualSustainability from "./png/VisualSustainability.png";
import YarnBadge from "./png/YarnBadge.png";

const TextureSet = () => {
  const dispatch = useDispatch();
  const textures = [
    { name: "Recycled Bamboo Chip", img: RecycledBambooChip },
    { name: "Plastisol", img: Plastisol },
    { name: "Felt Badge", img: FeltBadge },
    { name: "Heat Transfer", img: HeatTransfer },
    { name: "TPU", img: TPU },
    { name: "Marble TPU", img: MarbleTPU },
    { name: "Recycled Plastic Grind", img: RecycledPlasticGrind },
    { name: "Recycled Coffee Grind", img: RecycledCoffeeGrind },
    { name: "Sandblasted Reflective", img: SandblastedReflective },
    { name: "Reflective", img: Reflective },
    { name: "Yarn Badge", img: YarnBadge },
    { name: "Deboss Badge", img: DebossBadge },
    { name: "Laser Cut Badge", img: LaserCutBadge },
    { name: "Leather Badge", img: LeatherBadge },
    { name: "Leather Badge Laser Cut", img: LeatherBadgeLaserCut },
    { name: "Visual Sustainability", img: VisualSustainability },
  ];

  return (
    <div className="inline-flex items-center h-min">
      {textures.map((texture, index) => (
        <li key={index} className="w-max block">
          <button className=" border-gray-500 rounded-full mx-5" onClick={() => dispatch(setLogoTexture(texture.name))}>
            <img src={texture.img} alt={texture.name} height={52} width={52} />
          </button>
          <span className="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 whitespace-nowrap bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100">
            {texture.name}
          </span>
        </li>
      ))}
    </div>
  );
};

export default TextureSet;
