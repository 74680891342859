import React from "react";
import Button from "../../Partials/Button";
import Icons from "../../../assets/icons";

export default function Topbar() {
  return (
    <div className="absolute w-full z-10">
      <div className="flex justify-between items-center p-5">
        <div>
          <div>Product Name</div>
          <div>CHF $180</div>
        </div>
        <div className="flex gap-5 items-center justify-center">
          <Button className="w-10 h-10">
            <Icons src={"person"} />
          </Button>
          <Button className="w-10 h-10">
            <Icons src={"share"} />
          </Button>
          <Button className="px-8 py-2">
            <span>Done</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
