import React from "react";
import DesignOptions from "./Options";
import Pallete from "./Pallete";

export default function Home() {
  return (
      <div className="pt-20">
        <DesignOptions />
        <Pallete />
      </div>
  );
}
