import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { setColor } from "../../../../../../store/slice";
import { useDispatch } from "react-redux";
import "./picker.css";

export default function Picker() {
  const dispatch = useDispatch();
  const [color, setColors] = useState();

  dispatch(setColor(color));

  const handleHexInputChange = (e) => {
    const value = e.target.value;
    setColors(value);
  };

  return (
    <div className="absolute left-[8%] top-[30%]">
      <div className="border rounded-lg border-[#2B4984] p-2 w-min">
        <HexColorPicker
          color={color}
          onChange={setColors}
          style={{ height: 100, width: 170 }}
        />
        <div className="flex flex-row justify-start items-center gap-2 border border-[#eee] rounded mt-1 px-1">
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <circle cx="7" cy="7.5" r="7" fill={color} />
            </svg>
          </div>
          <div className="m-0">
            <input
              type="text"
              className="w-full border-0 p-0 m-0 outline-none bg-transparent"
              value={color}
              onChange={handleHexInputChange}
              maxLength="7"
              placeholder="#000000"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
