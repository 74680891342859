import React, { useMemo, useEffect, useState } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useSelector } from "react-redux";
import { useGLTF } from "@react-three/drei";
import DecalLogo from "./DecalLogo";

let ktx2LoaderInstance = null;

export default function Model(props) {
  const selectedPart = useSelector((state) => state.customizer.selectedPart);
  const selectedColor = useSelector((state) => state.customizer.selectedColor);
 

  const { gl } = useThree();
  const ktx2Loader = useMemo(() => {
    if (!ktx2LoaderInstance) {
      ktx2LoaderInstance = new KTX2Loader();
      ktx2LoaderInstance.setTranscoderPath(
        "https://cdn.jsdelivr.net/gh/pmndrs/drei-assets/basis/"
      );
      ktx2LoaderInstance.detectSupport(gl);
    }
    return ktx2LoaderInstance;
  }, [gl]);

  const { nodes, materials } = useLoader(
    GLTFLoader,
    "/Models/ktx2/hutter-shoe.gltf",
    (loader) => {
      loader.setKTX2Loader(ktx2Loader);
    }
  );

  // State to store colors for each part
  const [partsColor, setPartsColor] = useState({
    Vamp: null,
    Insole: null,
    OuterSole: null,
    Inside: null,
    Tongue: null,
    Lace: null,
    Foxing: null,
  });

  useEffect(() => {
    // Update color for the selected part
    setPartsColor((prevColors) => ({
      ...prevColors,
      [selectedPart]: selectedColor,
    }));
  }, [selectedPart, selectedColor]);

  // Fade in emissive color
  useEffect(() => {
    const fadeDuration = 500; // 0.5 second
    const intervalTime = 50; // update every 50ms
    const steps = fadeDuration / intervalTime;
    let currentStep = 0;

    // Reset emissive properties for all parts
    Object.keys(materials).forEach((partName) => {
      if (materials[partName]) {
        materials[partName].emissive.set(null); // Reset to no emissive color
        materials[partName].emissiveIntensity = 0; // Reset emissive intensity
      }
    });

    if (materials[selectedPart]) {
      materials[selectedPart].emissive.set("#FFFFFF"); // Set target emissive color
      const fadeInInterval = setInterval(() => {
        currentStep += 1;
        materials[selectedPart].emissiveIntensity = Math.min(
          (currentStep / steps) * 0.6,
          0.6
        ); // Fade in effect
        if (currentStep >= steps) {
          clearInterval(fadeInInterval);
        }
      }, intervalTime);

      // Remove the emissive effect after 2 seconds
      const timeoutId = setTimeout(() => {
        const fadeOutInterval = setInterval(() => {
          currentStep -= 1;
          materials[selectedPart].emissiveIntensity = Math.max(
            (currentStep / steps) * 0.6,
            0
          ); // Fade out effect
          if (currentStep <= 0) {
            clearInterval(fadeOutInterval);
            materials[selectedPart].emissive.set(null); // Reset to no emissive color
          }
        }, intervalTime);
      }, 500);

      // Cleanup intervals on component unmount or selectedPart change
      return () => {
        clearInterval(fadeInInterval);
        clearTimeout(timeoutId);
      };
    }
  }, [selectedPart, materials]);

  // Load decal texture

  useEffect(() => {
    // Apply colors to materials
    Object.keys(partsColor).forEach((partName) => {
      if (materials[partName]) {
        materials[partName].color.set(partsColor[partName]);
      }
    });
  }, [partsColor, materials]);

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Vamp.geometry}
        material={materials.Vamp}
        position={[-2.31, 0.106, 0.008]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.InSole.geometry}
        material={materials.InSole}
        position={[-2.317, -3.346, 0.039]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.OuterSole.geometry}
        material={materials.OuterSole}
        position={[-1.769, -3.659, 0.085]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.Inside.geometry}
        material={materials.Inside}
        position={[-2.273, 1.286, 0.021]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.Tongue.geometry}
        material={materials.Tongue}
        position={[-1.676, 3.189, -0.066]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.Lace.geometry}
        material={materials.Lace}
        position={[-2.156, 3.659, 0.079]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes.Foxing.geometry}
        material={materials.Foxing}
        position={[2.317, 0.502, -0.166]}
        rotation={[0, -1.571, 0]}
      >
        <DecalLogo/>
      </mesh>
    </group>
  );
}

useGLTF.preload("/Models/ktx2/hutter-shoe.gltf");
