import React from "react";
import Selection from "./Selection";
import Button from "../../../Partials/Button";
import Icons from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { setOption } from "../../../../store/slice";

export default function TrayHeader() {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.customizer.selectedOption);

  const toggleMenu = () => {
    const newOption = selectedOption === "Home" ? null : "Home";
    dispatch(setOption(newOption));
  };

  return (
    <div className="p-5 absolute w-full">
      <div className="inline-flex items-center justify-center gap-5">
        <Button className="w-10 h-10 relative" onClick={toggleMenu}>
          <Icons src={selectedOption ? "arrowBack" : "arrowUp"} />
        </Button>
        <div className="inline-flex gap-2 items-center justify-center">
          <span className="font-bold uppercase">{selectedOption ? selectedOption : "START"}</span>
          <Icons src="tooltip" />
        </div>
      </div>
      <Selection />
    </div>
  );
}
