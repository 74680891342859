import React from "react";
import Button from "../../../../Partials/Button";
import { useDispatch } from "react-redux";
import { setOption } from "../../../../../store/slice";

export default function DesignOptions() {
  const dispatch = useDispatch();

  return (
    <div className="m-auto flex items-center justify-center pt-14">
      <div className="m-auto flex gap-8 items-center justify-center">
        <Button className="w-40 py-1" onClick={() =>  dispatch(setOption('Logo'))}>
          <span className="m-auto">Logo</span>
        </Button>
        <Button className="w-40 py-1" onClick={() => dispatch(setOption('Pattern'))}>
          <span>Pattern</span>
        </Button>

        <Button className="w-40 py-1"  onClick={() => dispatch(setOption('Gradient'))}>
          <span>Gradient</span>
        </Button>
        <Button className="w-40 py-1"  onClick={() => dispatch(setOption('Text'))}>
          <span>Text</span>
        </Button>
        {/* <button className="rounded-full border-gray-400 bg-white border-[1px] px-8 py-2 items-center justify-center">
        <span>Advance Design</span>
      </button> */}
      </div>
    </div>
  );
}
