import React from "react";
import Color from "./Color";
import Picker from "./Picker";
export default function Pallete() {
  return (
    <div className="flex justify-center items-center mt-5 mb-20"> 
      <Picker/>
      <div className="m-auto flex justify-center items-center gap-8">
        <Color />
      </div>
      {/* {selectedColor && (
        <p className="text-center mt-4">
          Selected Color: <span style={{ color: selectedColor }}>{selectedColor}</span>
        </p>
      )} */}
    </div>
  );
}
