import arrowLeft from "./arrowLeft.svg";
import arrowRight from "./arrowRight.svg";
import arrowBack from "./arrowBack.svg";
import arrowUp from "./arrowUp.svg";
import menuBurger from "./menuBurger.svg";
import person from "./person.svg";
import share from "./share.svg";
import tooltip from "./tooltip.svg";
export default function Icons({ src }) {
  const icons = {
    arrowLeft,
    arrowRight,
    arrowBack,
    arrowUp,
    menuBurger,
    person,
    share,
    tooltip
  };

  const selectedIcon = icons[src];

  return (
    <img
      src={selectedIcon ? selectedIcon : undefined} 
      alt={src}
    />
  );
}
