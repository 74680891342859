import React from "react";
import TrayHeader from "./TrayHeader";
import Home from "./Home";
import Logo from "./Logo";

import { useSelector } from "react-redux";
import TrayBody from "../../Partials/TrayBody";

export default function Menu() {
  const selectedOption = useSelector((state) => state.customizer.selectedOption);

  const renderSelectedOption = () => {
    switch (selectedOption) {
      case "Home":
        return <Home />;
      case "Logo":
        return <Logo />;
      // case 'Textures':
      //   return <Textures />;
      // case 'Gradient':
      //   return <Gradient />;
      default:
        return null;
    }
  };

  return (
    <div className={`bg-white absolute left-0 bottom-0 w-full p-2 overflow-hidden ${selectedOption ? 'h-[230px]' : 'h-[80px]'}`}>
      <TrayHeader/>
      <TrayBody>{renderSelectedOption()}</TrayBody>
    </div>
  );
}
