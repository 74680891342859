import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedColor: null,
  selectedPart: null,
  selectedOption: null,
  selectedLogoTexture: null,
};

const customizerSlice = createSlice({
  name: "customizer",
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    setPart: (state, action) => {
      state.selectedPart = action.payload;
    },
    setOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setLogoTexture: (state, action) => {
      state.selectedLogoTexture = action.payload;
    }
  },
});
 
export const { setColor, setPart, setOption, setLogoTexture } = customizerSlice.actions;
export default customizerSlice.reducer;
