import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import Model from "./Model";
import { useSelector } from "react-redux";

export default function Scene() {
  const selectedColor = useSelector((state) => state.customizer.selectedColor);

  return (
    <div className="w-full h-full absolute z-0">
       <Canvas 
      style={{ width: "100%", height: "100%" }}
      camera={{ position: [0, 10, 30]}}
    >
      <color attach="background" args={['#F6F6F6']} />
      <Suspense fallback={null}>
        <Environment preset="sunset" />
        <directionalLight position={[0]} intensity={5} />
        <ambientLight intensity={1} />
        <Model color={selectedColor}/>
        <OrbitControls  
          enablePan={false}
          enableZoom={true}
          enableRotate={true}
          minDistance={20} 
          maxDistance={50}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 4}
          target={[0.14, -10 ,-0.2]}
        />
      </Suspense>
    </Canvas>
    </div>
   
  );
}
